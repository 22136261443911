<template>
  <v-container class="d-flex pa-0" style="gap: 20px">
    <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          hide-details
          :prepend-icon="icon"
          :label="dateLabel"
          readonly
          :value="formattedDate"
          :error-messages="errorMessages"
        />
      </template>
      <v-date-picker
        v-model="localDate"
        no-title
        @change="isOpen = false"
        @input="emitDate"
      />
    </v-menu>
    <v-combobox
      v-model="localTime"
      :items="timeOptions"
      hide-details
      :label="timeLabel"
      maxlength="5"
      :error-messages="errorMessages"
      @change="emitTime"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'DatePicker',

  props: {
    date: {
      type: String as PropType<string | null>,
      default: null,
    },
    time: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    dateLabel: {
      type: String as PropType<string | undefined>,
      default: '' as string,
    },
    timeLabel: {
      type: String as PropType<string | undefined>,
      default: '' as string,
    },
    errorMessages: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      timeOptions: [],
      localDate: this.date,
      localTime: this.time,
      isOpen: false,
    }
  },

  watch: {
    date(value) {
      this.localDate = value
    },
    time(value) {
      this.localTime = value
    },
  },

  computed: {
    formattedDate(): string {
      if (this.localDate == null) return ''
      const dateFormat = new Date(this.localDate)
      const year = dateFormat.getFullYear()
      const month = String(dateFormat.getMonth() + 1).padStart(2, '0')
      const day = String(dateFormat.getDate()).padStart(2, '0')

      const date = year + '/' + month + '/' + day

      return date as string
    },
  },

  mounted() {
    this.generateTimeOptions()
  },

  methods: {
    emitDate() {
      this.$emit('update:date', this.localDate)
      this.$emit('validate-period')
    },
    emitTime() {
      this.$emit('update:time', this.localTime)
      this.$emit('validate-period')
    },

    generateTimeOptions() {
      const times = []
      let start = new Date()
      start.setHours(0, 0, 0, 0)
      for (let i = 0; i < 96; i++) {
        times.push(start.toTimeString().slice(0, 5))
        start.setMinutes(start.getMinutes() + 15)
      }
      this.timeOptions = times as []
    },
  },
})
</script>
