import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"d-flex pa-0",staticStyle:{"gap":"20px"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"hide-details":"","prepend-icon":_vm.icon,"label":_vm.dateLabel,"readonly":"","value":_vm.formattedDate,"error-messages":_vm.errorMessages}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"change":function($event){_vm.isOpen = false},"input":_vm.emitDate},model:{value:(_vm.localDate),callback:function ($$v) {_vm.localDate=$$v},expression:"localDate"}})],1),_c(VCombobox,{attrs:{"items":_vm.timeOptions,"hide-details":"","label":_vm.timeLabel,"maxlength":"5","error-messages":_vm.errorMessages},on:{"change":_vm.emitTime},model:{value:(_vm.localTime),callback:function ($$v) {_vm.localTime=$$v},expression:"localTime"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }