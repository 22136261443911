import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    uid: null,
    customerId: null,
    email: null,
    idToken: null,
    isCreatingUser: false,
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setUid(state, uid) {
      state.uid = uid
    },
    setCustomerId(state, customerId) {
      state.customerId = customerId
    },
    setEmail(state, email) {
      state.email = email
    },
    setIdToken(state, idToken) {
      state.idToken = idToken
    },
    setIsCreatingUser(state, isCreatingUser) {
      state.isCreatingUser = isCreatingUser
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    },
  },
  getters: {
    uid: (state) => (state.uid ? state.uid : null),
    customerId: (state) => (state.customerId ? state.customerId : null),
    email: (state) => (state.email ? state.email : null),
    idtoken: (state) => (state.idToken ? state.idToken : null),
  },
  actions: {},
  modules: {},
  // リロード時にstore.stateを維持
  // authからstore.stateに代入している情報をauthから直接参照する場合は削除しても動作に影響なし
  // セキュリティ向上（特にユーザがサインアウトせずブラウザを閉じた場合）のためセッションストレージを指定、および攻撃を防ぐためキー名がvuexとなることを隠蔽
  plugins: [
    createPersistedState({
      key: 'langx-customer-console',
      storage: window.localStorage,
    }),
  ],
})
