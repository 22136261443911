<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}

.v-data-table__wrapper th:nth-child(2),
.v-data-table__wrapper td:nth-child(2) {
  max-width: 150px;
}

.v-data-table__wrapper th:nth-child(4),
.v-data-table__wrapper td:nth-child(4) {
  min-width: 240px;
}
</style>

<template>
  <div>
    <v-card width="60vw" class="ma-10 card_shadow">
      <v-container class="headline">
        <p>{{ $t('newPackage.header') }}</p>
      </v-container>
      <v-container class="d-flex flex-column pa-10 mt-n5" style="gap: 10px">
        <p class="text-h6">{{ $t('newPackage.subHeader') }}</p>

        <v-text-field
          v-model="packageCode"
          outlined
          persistent-placeholder
          :label="$t('newPackage.packageCode')"
          :rules="[rules.required, rules.validateHalfWidthAlphanumeric]"
          :error-messages="duplicatedPackageCodeMessage"
        ></v-text-field>

        <v-text-field
          v-model="packageName"
          outlined
          persistent-placeholder
          :label="$t('newPackage.packageName')"
          :rules="[rules.required]"
        ></v-text-field>

        <p class="text-body-2" v-html="$t('newPackage.coupon.text')"></p>

        <v-row>
          <v-col cols="7">
            <v-file-input
              v-model="file"
              accept="text/csv"
              persistent-placeholder
              outlined
              :label="$t('newPackage.coupon.couponFormat')"
              :error-messages="CSVInputErrorMessage"
              @change="validateCSVInputFile"
            ></v-file-input>
          </v-col>

          <v-col cols="5" class="mt-2">
            <v-btn class="btn_shadow_half" @click="getFileSample">
              <v-icon small> mdi-download </v-icon>
              {{ $t('newPackage.coupon.buttonText') }}
            </v-btn>
          </v-col>
        </v-row>

        <p class="text-body1">{{ $t('newPackage.viewingPeriod.text') }}</p>
        <div class="d-flex" style="gap: 20px">
          <v-card outlined>
            <v-card-text class="text-caption pb-0">
              <span style="background-color: white">
                {{ $t('newPackage.viewingPeriod.startAt') }}
              </span>
            </v-card-text>
            <v-container class="pb-6">
              <v-container class="d-flex" style="gap: 20px">
                <DateTimePicker
                  :date="startViewingDate"
                  :time="startViewingTime"
                  :timeOptions="timeOptions"
                  icon="mdi-calendar"
                  :dateLabel="dateLabel"
                  :timeLabel="timeLabel"
                  @update:date="startViewingDate = $event"
                  @update:time="startViewingTime = $event"
                  @validate-period="validateViewingPeriod"
                />
              </v-container>
            </v-container>
          </v-card>

          <v-card outlined>
            <v-card-text class="text-caption pb-0">
              <span style="background-color: white">
                {{ $t('newPackage.viewingPeriod.endAt') }}
              </span>
            </v-card-text>
            <v-container class="pb-6">
              <v-container class="d-flex" style="gap: 20px">
                <DateTimePicker
                  :date="endViewingDate"
                  :time="endViewingTime"
                  :timeOptions="timeOptions"
                  icon="mdi-calendar"
                  :dateLabel="dateLabel"
                  :timeLabel="timeLabel"
                  @update:date="endViewingDate = $event"
                  @update:time="endViewingTime = $event"
                  @validate-period="validateViewingPeriod"
                  :error-messages="viewingPeriodErrorMessage"
                />
              </v-container>
              <span class="error--text text-caption">
                {{ viewingPeriodErrorMessage }}
              </span>
            </v-container>
          </v-card>
        </div>

        <div class="pt-5">
          <p class="text-h6">{{ $t('newPackage.campaign.text') }}</p>

          <v-simple-table class="pb-5">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('newPackage.campaign.header[0]') }}
                  </th>
                  <th class="text-left">
                    {{ $t('newPackage.campaign.header[1]') }}
                  </th>
                  <th class="text-left">
                    {{ $t('newPackage.campaign.header[2]') }}
                  </th>
                  <th class="text-left">
                    {{ $t('newPackage.campaign.header[3]') }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(campaign, index) in newCampaign" :key="index">
                  <td>
                    <v-text-field
                      v-model="campaign.name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="campaign.scenarioDocId"
                      :items="scenarios"
                      item-text="scenarioName"
                      item-value="scenarioDocId"
                    ></v-select>
                  </td>
                  <td><v-container class="text-center">1</v-container></td>
                  <td>
                    <v-container class="d-flex pa-0">
                      <DateTimePicker
                        :date="campaign.startInterviewDate"
                        :time="campaign.startInterviewTime"
                        :timeOptions="timeOptions"
                        @update:date="campaign.startInterviewDate = $event"
                        @update:time="campaign.startInterviewTime = $event"
                        @validate-period="validateViewingPeriod(campaign)"
                      />
                    </v-container>

                    <v-container class="d-flex pa-0" style="gap: 10px">
                      <v-icon small class="pa-0">mdi-tilde</v-icon>
                      <v-container>
                        <DateTimePicker
                          :date="campaign.endInterviewDate"
                          :time="campaign.endInterviewTime"
                          :timeOptions="timeOptions"
                          @update:date="campaign.endInterviewDate = $event"
                          @update:time="campaign.endInterviewTime = $event"
                          @validate-period="validateViewingPeriod(campaign)"
                          :error-messages="campaign.errorMessage"
                        />
                      </v-container>
                    </v-container>
                    <span class="error--text text-caption">
                      {{ campaign.errorMessage }}
                    </span>
                  </td>
                  <td>
                    <v-container class="d-flex">
                      <v-btn
                        small
                        icon
                        @click="moveUp(index)"
                        :disabled="index == 0"
                      >
                        <v-icon small>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                        small
                        icon
                        @click="moveDown(index)"
                        :disabled="index == newCampaign.length - 1"
                      >
                        <v-icon small>mdi-arrow-down</v-icon>
                      </v-btn>
                      <v-btn small icon @click="deleteCampaign">
                        <v-icon small>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-container>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-btn
            style="width: 100%"
            small
            elevation="0"
            @click="createNewCampaign"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-container class="d-flex justify-center pa-8">
          <v-dialog width="600" persistent>
            <template #activator="{ on, attrs }">
              <v-btn
                color="#1b2643"
                class="white--text"
                v-bind="attrs"
                v-on="on"
                :disabled="isInputError"
              >
                {{ $t('newPackage.campaign.confirm') }}
              </v-btn>
            </template>
            <template #default="dialog">
              <ConfirmCreateCampaignTable
                :scenarios="scenarios"
                :campaigns="newCampaign"
                :coupons="coupons"
                :packageCode="packageCode"
                :packageName="packageName"
                :startViewingDate="startViewingDate"
                :startViewingTime="startViewingTime"
                :endViewingDate="endViewingDate"
                :endViewingTime="endViewingTime"
                :startInterviewDate="startInterviewDate"
                :startInterviewTime="startInterviewTime"
                :endInterviewDate="endInterviewDate"
                :endInterviewTime="endInterviewTime"
                @cancel="dialog.value = false"
                @resetValues="resetValues"
              />
            </template>
          </v-dialog>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import ConfirmCreateCampaignTable from '@/components/Dashboard/PageViewItems/ConfirmCreateCampaignTable.vue'
import DateTimePicker from '@/components/Dashboard/PageViewItems/DateTimePicker.vue'
import { db, functions } from '@/plugins/firebase'
import type { Customer } from '@/resources/customer'
import type { NewCampaign } from '@/resources/newCampaign'
import type { Package } from '@/resources/package'
import store from '@/store'
import { defineComponent } from '@vue/composition-api'
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import Papa from 'papaparse'

type Campaign = {
  campaignId?: string
  campaignCode?: string
  campaignName?: string
}

type Coupon = {
  couponCode: string
  password: string
}

type Scenario = {
  scenarioDocId: string
  scenarioName: string
}

type AgentGroups = {
  agentGroupId: string
  agentGroupName: string
}

export default defineComponent({
  name: 'PackageNew',
  components: { DateTimePicker, ConfirmCreateCampaignTable },

  data() {
    return {
      file: null as File | null,
      campaigns: [] as Campaign[],
      newCampaign: [
        {
          name: '',
          scenarioDocId: '',
          startInterviewDate: null,
          startInterviewTime: '',
          endInterviewDate: null,
          endInterviewTime: '',
          errorMessage: '',
          limits: 1,
          startAt: '',
          endAt: '',
        },
      ] as NewCampaign[],
      coupons: [] as Coupon[],
      scenarios: [] as Scenario[],
      packageCode: '' as string,
      packageName: '' as string,
      timeOptions: [],

      duplicatedPackageCodeMessage: '' as string,
      CSVInputErrorMessage: '' as string | null,
      viewingPeriodErrorMessage: '' as string | '',
      interviewPeriodErrorMessage: '' as string | '',

      startViewingDate: null as string | null,
      startViewingTime: '' as string,
      endViewingDate: null as string | null,
      endViewingTime: '' as string,

      startInterviewDate: null as string | null,
      startInterviewTime: '' as string,
      endInterviewDate: null as string | null,
      endInterviewTime: '' as string,
    }
  },

  async mounted() {
    this.getCampaigns()
    this.getScenarios()
  },

  watch: {
    packageCode(value) {
      this.validateduplicatedCode(value)
    },
  },

  computed: {
    rules() {
      return {
        required: (value: string) =>
          !!value || this.$t('newPackage.validation.required'),
        validateHalfWidthAlphanumeric: (value: string) =>
          /^[a-zA-Z0-9-_]*$/.test(value) ||
          this.$t(
            'newPackage.validation.packageCode.halfWithAlphanumbericError'
          ),
      }
    },

    isInputError() {
      let disabled = false
      if (this.packageCode === '') {
        disabled = true
      }
      if (this.packageCode && !/^[a-zA-Z0-9-_]*$/.test(this.packageCode)) {
        disabled = true
      }
      if (this.packageName === '') {
        disabled = true
      }
      if (this.newCampaign.some((campaign) => campaign.name === '')) {
        disabled = true
      }

      if (this.newCampaign.some((campaign) => campaign.errorMessage !== '')) {
        disabled = true
      }

      if (this.viewingPeriodErrorMessage !== '') {
        disabled = true
      }

      if (this.interviewPeriodErrorMessage !== '') {
        disabled = true
      }

      if (this.CSVInputErrorMessage !== '') {
        disabled = true
      }

      return disabled
    },

    dateLabel(): string {
      return this.$t('newPackage.viewingPeriod.date') as string
    },

    timeLabel(): string {
      return this.$t('newPackage.viewingPeriod.time') as string
    },
  },

  methods: {
    async getCampaigns() {
      const campaignsRef = collection(db, 'campaigns')

      await getDocs(query(campaignsRef)).then((campaignData) => {
        campaignData.forEach(async (doc) => {
          this.campaigns.push({
            campaignId: doc.id,
            campaignCode: doc.data().campaignCode,
            campaignName: doc.data().name,
          })
        })
      })
    },

    async getPackage() {
      const getPackage = httpsCallable(functions, 'getPackage')
      const response = (await getPackage({ packageCode: this.packageCode }))
        .data as Package
      if (response && response.data) {
        return true
      }
      return false
    },

    async getScenarios() {
      const agentGroups = [] as AgentGroups[]
      const agentGroupRef = collection(db, 'agentGroups')
      await getDocs(query(agentGroupRef)).then((agentGropSnapshot) => {
        agentGropSnapshot.forEach((doc) => {
          agentGroups.push({
            agentGroupId: doc.id,
            agentGroupName: doc.data().name,
          })
        })
      })

      const customerId = store.getters.customerId
      const customer = (
        await getDoc(doc(db, 'customers', customerId))
      ).data() as Customer

      const scenarioDocIds = Array.isArray(customer.scenarioDocIds)
        ? customer.scenarioDocIds
        : [customer.scenarioDocIds]

      function chunk<T>(array: T[], size: number): T[][] {
        const chunks: T[][] = []
        for (let i = 0; i < array.length; i += size) {
          chunks.push(array.slice(i, i + size))
        }
        return chunks
      }

      const scenarioDocChunks = chunk(scenarioDocIds, 30)

      const scenariosRef = collection(db, 'scenarios')
      const scenarios: Scenario[] = []

      let scenariosQuery = scenarioDocChunks.map((docIds) =>
        getDocs(query(scenariosRef, where(documentId(), 'in', docIds)))
      )

      await Promise.all(scenariosQuery).then((results) => {
        results.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            const agentGroup = agentGroups.find(
              (agent) => agent.agentGroupId === doc.data().agentGroupId
            )
            scenarios.push({
              scenarioDocId: doc.id,
              scenarioName: `${doc.data().name} (${
                agentGroup?.agentGroupName
              })`,
            })
          })
        })
      })
      this.scenarios = scenarios
    },

    getFileSample() {
      const csvContent = [
        ['couponCode0', 'password0'],
        ['couponCode1', 'password1'],
        ['couponCode2', 'password2'],
        ['couponCode3', 'password3'],
        ['couponCode4', 'password4'],
      ]

      const csvString = Papa.unparse(csvContent)
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)

      link.setAttribute('href', url)
      link.setAttribute('download', 'coupon-sample.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    formattedDateTime(date: Date | null, time = '') {
      if (!date) return time as string
      const dateFormat = new Date(date)
      const year = dateFormat.getFullYear()
      const day = String(dateFormat.getDate()).padStart(2, '0')
      const month = String(dateFormat.getMonth() + 1).padStart(2, '0')

      const dateTime = year + '/' + month + '/' + day + ' ' + time

      return dateTime as string
    },

    async validateduplicatedCode(value: string) {
      const isDuplicatedPackageCode = await this.getPackage()
      const isDuplicatedCampaignCode = this.campaigns.some(
        (campaign) => campaign.campaignCode === value
      )

      if (isDuplicatedCampaignCode || isDuplicatedPackageCode) {
        this.duplicatedPackageCodeMessage = this.$t(
          'newPackage.validation.packageCode.duplicatedCodeError'
        ) as string
      } else {
        this.duplicatedPackageCodeMessage = ''
      }
    },

    validateCSVInputFile(file: File) {
      if (!file) {
        this.CSVInputErrorMessage = ''
        return
      }

      Papa.parse(file, {
        complete: (result: any) => {
          const data = result.data

          if (data.length === 0) return

          const codes = new Set()
          for (let i = 0; i < data.length; i++) {
            const row = data[i]

            if (row.length === 0) continue

            //Check for blank coupon or password
            const [couponCode, password] = row
            if (!couponCode || !password) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.blankCouponError'
              ) as string
              break
            }

            //Check for more or less columns
            if (row.length !== 2) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.incompleteColumnError'
              ) as string
              break
            }

            // Check for duplicated coupon codes
            if (codes.has(couponCode)) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.duplicatedCouponsError'
              ) as string
              break
            } else {
              codes.add(couponCode)
            }

            // Check if the coupon code or password contains only allowed characters
            const validPattern = /^[A-Za-z0-9\-_]+$/
            if (
              !validPattern.test(couponCode) ||
              !validPattern.test(password)
            ) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.halfWithAlphanumbericError'
              ) as string
              break
            }

            this.coupons = data.map((coupon: [string, string]) => ({
              couponCode: coupon[0],
              password: coupon[1],
            }))
          }
        },
      })
    },

    validateViewingPeriod(campaign: NewCampaign = {} as NewCampaign) {
      this.viewingPeriodErrorMessage = ''
      this.interviewPeriodErrorMessage = ''
      campaign.errorMessage = ''

      if (campaign.startInterviewDate || campaign.endInterviewDate) {
        this.startInterviewDate = campaign.startInterviewDate || null
        this.startInterviewTime = campaign.startInterviewTime || ''
        this.endInterviewDate = campaign.endInterviewDate || null
        this.endInterviewTime = campaign.endInterviewTime || ''
      }

      const startViewingDateTime = new Date(
        `${this.startViewingDate}T${this.startViewingTime || '00:00'}`
      )
      const endViewingDateTime = new Date(
        `${this.endViewingDate}T${this.endViewingTime || '00:00'}`
      )

      const startInterviewDateTime = new Date(
        `${this.startInterviewDate}T${this.startInterviewTime || '00:00'}`
      )
      const endInterviewDateTime = new Date(
        `${this.endInterviewDate}T${this.endInterviewTime || '00:00'}`
      )

      campaign.startAt = `${this.startInterviewDate} ${
        this.startInterviewTime || '00:00'
      }`

      campaign.endAt = `${this.endInterviewDate}T${
        this.endInterviewTime || '00:00'
      }`

      if (endViewingDateTime < startViewingDateTime) {
        return (this.viewingPeriodErrorMessage = this.$t(
          'newPackage.validation.dateAndTime.endDateError'
        ) as string)
      }

      if (endInterviewDateTime < startInterviewDateTime) {
        return (campaign.errorMessage = this.$t(
          'newPackage.validation.dateAndTime.endDateError'
        ) as string)
      }

      if (
        startViewingDateTime > startInterviewDateTime ||
        endViewingDateTime < endInterviewDateTime
      ) {
        return (campaign.errorMessage = this.$t(
          'newPackage.validation.dateAndTime.interviewPeriodError'
        ) as string)
      }
      return
    },

    createNewCampaign() {
      this.newCampaign.push({
        name: '',
        scenarioDocId: '',
        startInterviewDate: null,
        startInterviewTime: '',
        endInterviewDate: null,
        endInterviewTime: '',
        errorMessage: '',
        limits: 1,
      })
    },

    deleteCampaign(index: number) {
      if (confirm(this.$t('newPackage.campaign.delete') as string)) {
        this.newCampaign.splice(index, 1)
      }
    },
    moveUp(index: number) {
      if (index > 0) {
        const temp = this.newCampaign[index]
        this.$set(this.newCampaign, index, this.newCampaign[index - 1])
        this.$set(this.newCampaign, index - 1, temp)
      }
    },
    moveDown(index: number) {
      if (index < this.newCampaign.length - 1) {
        const temp = this.newCampaign[index]
        this.$set(this.newCampaign, index, this.newCampaign[index + 1])
        this.$set(this.newCampaign, index + 1, temp)
      }
    },

    resetValues() {
      this.file = null
      this.campaigns = []
      this.newCampaign = [
        {
          name: '',
          scenarioDocId: '',
          startInterviewDate: null,
          startInterviewTime: '',
          endInterviewDate: null,
          endInterviewTime: '',
          errorMessage: '',
          startAt: '',
          endAt: '',
        },
      ]
      this.coupons = []
      this.scenarios = []
      this.packageCode = ''
      this.packageName = ''
      this.timeOptions = []
      this.duplicatedPackageCodeMessage = ''
      this.CSVInputErrorMessage = ''
      this.viewingPeriodErrorMessage = '' as string | ''
      this.interviewPeriodErrorMessage = '' as string | ''
      this.startViewingDate = null
      this.startViewingTime = ''
      this.endViewingDate = null
      this.endViewingTime = ''
      this.startInterviewDate = null
      this.startInterviewTime = ''
      this.endInterviewDate = null
      this.endInterviewTime = ''
    },
  },
})
</script>
