<style scoped>
.full-height-width-card {
  min-height: 100vh;
  width: 100vw;
}
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}
.title {
  font: normal normal normal 24px/36px Roboto;
}
.filled_noline >>> .v-input__slot::before {
  border-style: none !important;
}
.under-line {
  text-decoration: underline;
}
</style>

<template>
  <v-card
    color="transparent"
    flat
    class="full-height-width-card align-center justify-center"
  >
    <v-row justify="center" v-show="show1">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center">
                <p class="title">{{ $t('common.langxConsole') }}</p>
              </v-row>
              <v-row justify="center" class="mt-0">
                <p class="title">
                  {{ $t('signUp.registerNewAdminUserRequest') }}
                </p>
              </v-row>
              <v-row justify="center" class="mt-5">
                <v-col cols="10">
                  <v-text-field
                    v-model="candidate.customerCode"
                    :label="$t('common.customerCode')"
                    persistent-placeholder
                    outlined
                    required
                    :rules="[rules.required, rules.onlyAlphaNumericHyphen]"
                    @input="checkValidity"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-col cols="10">
                  <v-text-field
                    v-model="candidate.email"
                    :label="$t('common.email')"
                    persistent-placeholder
                    outlined
                    required
                    :rules="[rules.required, rules.emailRules]"
                    :error-messages="emailErrorMessages"
                    @input="checkValidity"
                  ></v-text-field>
                </v-col>
              </v-row>
              <input-new-pass
                :isSignUp="true"
                @setPassword="setPassword"
                @validatePassword="setPasswordValidity"
              />
              <v-row justify="center" class="mt-n7">
                <v-col cols="10">
                  <p
                    class="text-center"
                    v-html="$t('securitySettings.passwordCriteriaDescription')"
                  ></p>
                </v-col>
              </v-row>
              <v-row justify="center" class="mb-10">
                <v-btn
                  :disabled="isButtonDisabled"
                  color="#1b2643"
                  class="white--text"
                  @click="show1 = false"
                >
                  {{ $t('common.register') }}
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="mt-4 under-line"
                  @click="goToSignIn"
                >
                  {{ $t('signUp.signInHereButton') }}
                </v-btn>
              </v-row>
              <v-snackbar v-model="snackbar">
                {{ snackBarMessage }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-container>
          </v-form>
        </v-card>
        <div class="d-flex justify-center">
          <language-switching color="black" />
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-show="!show1 && show2">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center">
                <p class="title">{{ $t('common.langxConsole') }}</p>
              </v-row>
              <v-row justify="center" class="mt-0">
                <p class="title">{{ $t('signUp.registerWithThisContent') }}</p>
              </v-row>
              <v-row justify="center" class="mt-7">
                <v-col cols="10">
                  <v-text-field
                    v-model="candidate.customerCode"
                    :label="$t('common.customerCode')"
                    readonly
                    persistent-placeholder
                    required
                    class="filled_noline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10">
                  <v-text-field
                    v-model="candidate.email"
                    :label="$t('common.email')"
                    readonly
                    persistent-placeholder
                    required
                    class="filled_noline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10">
                  <v-text-field
                    :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword3 ? 'text' : 'password'"
                    @click:append="showPassword3 = !showPassword3"
                    v-model="password"
                    :label="$t('common.password')"
                    readonly
                    persistent-placeholder
                    required
                    class="filled_noline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-5 mb-2">
                <v-btn
                  text
                  color="blue"
                  class="mr-4 under-line"
                  @click="show1 = !show1"
                >
                  {{ $t('common.back') }}
                </v-btn>
                <v-btn color="#1b2643" class="white--text" @click="signUp">
                  {{ $t('common.register') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <div class="d-flex justify-center">
          <language-switching color="black" />
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-show="!show2">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center" class="py-8">
                <h2>{{ $t('signUp.registrationThankYouMessage') }}</h2>
                <!-- {{ uid }} -->
              </v-row>
              <v-row justify="center" class="mt-7">
                <p>
                  {{ $t('signUp.confirmationEmailSentMessage') }}
                </p>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="mt-10 under-line"
                  @click="goToSignIn"
                >
                  {{ $t('signUp.moveToSignInScreen') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <div class="d-flex justify-center">
          <language-switching color="black" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db, auth } from '@/plugins/firebase'
import {
  doc,
  collection,
  setDoc,
  query,
  getDocs,
  where,
} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from 'firebase/auth'

import InputNewPass from '@/components/Dashboard/PageViewItems/InputNewPasswordField.vue'
import LanguageSwitching from '@/components/Common/LanguageSwitchingComponent.vue'

export default defineComponent({
  name: 'SignUpView',
  components: {
    InputNewPass,
    LanguageSwitching,
  },
  data() {
    return {
      candidateRef: null,
      candidate: {
        customerCode: '',
        customerName: '',
        email: '',
        password: '',
        isAccepted: false,
      },
      snackbar: false,
      snackBarMessage: 'データベース接続エラー',

      show1: true,
      show2: true,

      password: '',

      showPassword3: false,
      customerName: '',
      customerId: '',
      uid: '',
      isPasswordValid: true,
      isEmailValid: false,
      isCustomerCodeValid: false,
      signUpError: null as string | null,
    }
  },

  created() {
    // サインイン画面を開くと強制サインアウトおよびストレージのリセット
    signOut(auth).then(() => {
      this.$store.commit('reset', this.$store.state)
    })
  },

  methods: {
    async signUp() {
      this.show2 = !this.show2
      // Auth認証及びエラーハンドリング
      try {
        this.$store.commit('setIsCreatingUser', true)
        await createUserWithEmailAndPassword(
          auth,
          this.candidate.email,
          this.password
        ).then(() => {
          this.$store.commit('setIsCreatingUser', false)
        })
      } catch (error) {
        if (error && typeof error === 'object' && 'code' in error) {
          this.signUpError = (error as { code: string }).code
        }
        console.log(error)
        this.$store.commit('setIsCreatingUser', false)
      }

      if (this.signUpError) {
        this.show1 = !this.show1
        this.show2 = !this.show2
        return
      }

      if (auth.currentUser) {
        this.uid = auth.currentUser.uid
        // store.state.emailがnullの状態でauthのstateが変更されると、
        // router設定の不正画面遷移処理の影響でサインインにリダイレクトすることを回避
        this.$store.commit('setEmail', auth.currentUser.email)
        // console.log('次のユーザに認証メールを送付します:', auth.currentUser.uid)
        const url = `${window.location.origin}/verified`
        sendEmailVerification(auth.currentUser, { url })
      }

      // CustomerName, customerIdを取得
      // await this.setCustomerIdAndName(this.candidate.customerCode)
      // console.log('CustomerNameを取得: ', this.customerName)
      // console.log('CustomerIdを取得:', this.customerId)

      // データ追加（ドキュメント自動作成）
      await setDoc(doc(db, 'candidates', this.uid), {
        customerCode: this.candidate.customerCode,
        email: this.candidate.email,
        isAccepted: this.candidate.isAccepted,
      })
    },

    async setCustomerIdAndName(customerCode: string) {
      // Auth登録後にcustomerIdを取得
      // customerCodeがuniqueが確定的な場合、本メソッドはgetDocs, docSnap.forEachを中心に全体的な修正が必要
      const q = query(
        collection(db, 'customers'),
        where('customerCode', '==', customerCode)
      )
      // customerCodeがヒットしなかった時の例外処理追加必要
      const docSnap = await getDocs(q)

      // 初回に一致のときのみcustomerName, Idをセット（ただし通常、ループは1回のみなので実質動作は不変）
      docSnap.forEach((doc) => {
        if (this.customerName == '') {
          this.customerName = doc.data().name
          this.customerId = doc.id
        }
      })
    },
    goToSignIn() {
      this.$router.push({ name: 'Sign In' })
    },
    // コンポーネントからの値の受け取り
    setPassword(pass: string) {
      this.password = pass
    },
    setPasswordValidity(isPasswordValid: boolean) {
      this.isPasswordValid = isPasswordValid
    },
    /**
     * inputイベントが発生時にエラーフラグを初期化して、isCustomerCodeValid、isEmailValidのチェックを行う。
     */
    checkValidity() {
      this.signUpError = ''
      this.isCustomerCodeValid =
        this.rules.onlyAlphaNumericHyphen(this.candidate.customerCode) !== true
      this.isEmailValid = this.rules.emailRules(this.candidate.email) !== true
    },
  },
  computed: {
    isButtonDisabled() {
      return !(
        !this.isPasswordValid &&
        !this.isCustomerCodeValid &&
        !this.isEmailValid
      )
    },
    rules() {
      return {
        required: (v: string) => !!v || this.$t('signUp.required'),
        emailRules: (v: string) => {
          const hasEmailFormat = /.+@.+\..+/.test(v)
          return hasEmailFormat || this.$t('signUp.invalidEmailFormat')
        },
        onlyAlphaNumericHyphen: (v: string) => {
          const isOnlyAlphaNumericHyphen = /^[a-zA-Z0-9-]+$/.test(v)
          return (
            isOnlyAlphaNumericHyphen || this.$t('signUp.alphaNumericHyphenOnly')
          )
        },
      }
    },
    emailErrorMessages() {
      if (this.signUpError === 'auth/email-already-in-use') {
        return this.$t('signUp.emailAlreadyUsed')
      }
      if (this.signUpError === 'auth/invalid-email') {
        return this.$t('signUp.invalidEmailFormat')
      }
      return ''
    },
  },
})
</script>
