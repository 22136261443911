<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: #ffffff;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
  font-size: 16px;
}

.bold-large-text {
  font-weight: bold;
  font-size: 18px;
}

td {
  white-space: nowrap;
}
td.displayName-column {
  min-width: 150px;
  max-width: 150px;
  white-space: normal;
  overflow-wrap: break-word;
}

td.dialogId-column {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.score {
  text-align: center;
}

.freeze-columns .v-data-table__wrapper {
  overflow-x: auto;
  position: relative;
}

.freeze-columns th:nth-child(-n + 4),
.freeze-columns td:nth-child(-n + 4) {
  position: relative;
  z-index: 1;
  background-color: white;
}

.freeze-columns th:nth-child(4),
.freeze-columns td:nth-child(4) {
  border-right: 2px solid #ccc;
}

::v-deep .freeze-columns .v-data-table-header th:nth-child(-n + 4) {
  position: relative;
  z-index: 1;
  background-color: white;
}
</style>

<template>
  <div>
    <!-- Campaign not found -->
    <v-card class="ma-10 card_shadow" v-if="error">
      <v-container fluid>
        <v-row class="headline">
          <v-col class="active_text">
            <p>{{ $t('campaignResult.cardTitle') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="mb-0">{{ $t('campaignResult.campaignNameError') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ma-10 card_shadow" v-else>
      <v-container fluid>
        <v-row class="headline">
          <v-col class="active_text">
            <p>{{ $t('campaignResult.cardTitle') }}</p>
          </v-col>
        </v-row>

        <v-row class="pl-6">
          <v-col class="bold-large-text">{{ campaignCodeName }}</v-col>
        </v-row>

        <v-row class="pl-6">
          <v-col
            >{{ $t('campaignResult.lastRetrievedTime') }}:
            {{ lastRetrievedTime }}</v-col
          >
        </v-row>

        <v-row class="pl-6">
          <v-col>
            <v-data-table
              dense
              multi-sort
              :headers="headers"
              :items="recalculateRows()"
              :footer-props="{
                'items-per-page-options': [100, 300, 1000, 5000, -1],
              }"
              :loading="loading"
              :loading-text="$t('campaignResult.loadingText')"
              class="freeze-columns"
              ref="dataTable"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.couponCode }}</td>
                  <td class="displayName-column">{{ item.displayName }}</td>
                  <td
                    v-if="item.status === $t('campaignResult.status.analyzed')"
                    class="dialogId-column"
                  >
                    <router-link
                      :to="`/dashboard/conversation-analysis/${item.dialogId}`"
                    >
                      {{ item.dialogId }}
                    </router-link>
                  </td>
                  <td v-else class="dialogId-column">{{ item.dialogId }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.startedAt }}</td>
                  <td>{{ item.completedAt }}</td>
                  <td>{{ item.duration }}</td>
                  <td :class="cefrStyleClass(item.overall)">
                    {{ item.overall }}
                  </td>
                  <td :class="cefrStyleClass(item.range)">
                    {{ item.range }}
                  </td>
                  <td :class="cefrStyleClass(item.accuracy)">
                    {{ item.accuracy }}
                  </td>
                  <td :class="cefrStyleClass(item.phonology)">
                    {{ item.phonology }}
                  </td>
                  <td :class="cefrStyleClass(item.fluency)">
                    {{ item.fluency }}
                  </td>
                  <td :class="cefrStyleClass(item.coherence)">
                    {{ item.coherence }}
                  </td>
                  <td :class="cefrStyleClass(item.interaction)">
                    {{ item.interaction }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { db, functions } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  where,
  Firestore,
  doc,
  getDoc,
} from 'firebase/firestore'
import store from '@/store'
import { defineComponent } from '@vue/composition-api'
import { httpsCallable } from 'firebase/functions'
import { getJstTime, calculateDuration } from '@/components/utils/dateUtils'
import type { Campaign } from '@/resources/campaign'
import type { TableHeader, TableBody } from '@/resources/table'
import type { Result } from '@/resources/result'
import type { Coupon } from '@/resources/coupon'
import dayjs from 'dayjs'
import Vue from 'vue'
import {
  cefrStyleClass,
  roundToTwoDecimals,
} from '@/components/utils/commonUtils'

export default defineComponent({
  name: 'CampaignResults',
  data() {
    return {
      headers: [] as TableHeader[],
      rows: [] as TableBody[],
      lastRetrievedTime: '',
      campaignCodeName: '',
      error: false,
      loading: true,
      coupons: [] as Coupon[],
    }
  },
  watch: {
    '$i18n.locale'() {
      this.updateHeaders()
      this.updateStatusTexts()
      this.recalculateRows()
    },
  },
  created() {
    this.updateHeaders()
  },
  async mounted() {
    const campaignCode = this.$route.params.campaignCode
    const customerId = store.state.customerId

    this.addScrollListener()

    try {
      const campaignData = await this.getCampaignByCode(db, campaignCode)

      if (!campaignData || campaignData.customerId !== customerId) {
        this.error = true
        return
      }

      const results = (await this.getResults(campaignData.campaignCode)) || []

      const campaignId = campaignData.id

      this.campaignCodeName = `${campaignData.campaignCode} (${campaignData.name})`

      // Step 1: Get the list of coupons for the campaign from Firestore
      const couponsSnapshot = await getDocs(
        collection(db, 'campaigns', campaignId, 'coupons')
      )
      const coupons = couponsSnapshot.docs.map((doc) => doc.data()) as Coupon[]
      this.coupons = coupons
      const learners = await this.getLearners(this.coupons)

      // Step 3: Iterating each row of the coupon records (from Step 1), find all matching result records (from Step 2) for the coupon
      const rows = await Promise.all(
        coupons.map(async (coupon) => {
          const matchingResults = results.filter(
            (result) => result.userInfo.couponCode === coupon.couponCode
          )

          if (matchingResults.length === 0) {
            return [
              {
                couponCode: coupon.couponCode,
                displayName: learners.find(
                  (learner) => learner.id === coupon.learnerId
                )?.displayName,
                dialogId: '',
                status: coupon.isConfirmed
                  ? (this.$t('campaignResult.status.registered') as string)
                  : (this.$t('campaignResult.status.unregistered') as string),
                startedAt: '',
                completedAt: '',
                duration: '',
                overall: '',
                range: '',
                accuracy: '',
                phonology: '',
                fluency: '',
                coherence: '',
                interaction: '',
              },
            ]
          }
          // Step 4-2: If there’s just one, render one row with the status and results
          // Step 4-3: If there are more than one (say 3), render three rows with the status and results
          return matchingResults.map((result) => {
            const fastResult = result.fastResult || {}
            const score = fastResult.score || {}

            return {
              couponCode: result.userInfo.couponCode,
              dialogId: result.dialogStatus.channelName,
              displayName:
                learners.find((learner) => learner.id === coupon.learnerId)
                  ?.displayName ?? '',
              status: this.determineStatus(
                coupon.isConfirmed,
                result.dialogStatus.isAssessed,
                result.dialogStatus.isEnded
              ),
              startedAt: getJstTime(result.dialogStatus.dialogStartedAt),
              completedAt: getJstTime(result.dialogStatus.dialogEndedAt),
              duration: calculateDuration(
                getJstTime(result.dialogStatus.dialogStartedAt),
                getJstTime(result.dialogStatus.dialogEndedAt)
              ),
              overall:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.cefrOverall
                  ? score.cefrOverall.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.cefrOverall.score)})`
                  : '',
              range:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.range
                  ? score.range.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.range.score)})`
                  : '',
              accuracy:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.accuracy
                  ? score.accuracy.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.accuracy.score)})`
                  : '',
              phonology:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.phonology
                  ? score.phonology.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.phonology.score)})`
                  : '',
              fluency:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.fluency
                  ? score.fluency.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.fluency.score)})`
                  : '',
              coherence:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.coherence
                  ? score.coherence.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.coherence.score)})`
                  : '',
              interaction:
                (result.dialogStatus.shouldAssess === true ||
                  (result.dialogStatus.shouldAssess === undefined &&
                    result.dialogStatus.isAssessed === true)) &&
                fastResult &&
                score.interaction
                  ? score.interaction.cefrLevel +
                    ` (${this.roundToTwoDecimals(score.interaction.score)})`
                  : '',
            }
          })
        })
      )
      this.rows = rows.flat()
      this.recalculateRows()
      this.loading = false
      this.lastRetrievedTime = getJstTime(dayjs().toISOString())
    } catch (error) {
      this.loading = false
      this.lastRetrievedTime = getJstTime(dayjs().toISOString())
      console.log(error)
      this.error = true
    }
  },
  methods: {
    /**
     * Recalculates the rows data by checking the 'interaction' field of each row.
     * If the 'interaction' field contains '-1', it replaces the value with a localized
     * string representing 'unrated'. Otherwise, it retains the original 'interaction' value.
     *
     * @returns {Array} A new array of rows with updated 'interaction' values.
     */
    recalculateRows() {
      return this.rows.map((row) => ({
        ...row,
        interaction: row.interaction.includes('-1')
          ? String(this.$t('conversationAnalysis.unrated'))
          : row.interaction,
      }))
    },
    /**
     * Fetches a campaign by its unique code from the Firestore database.
     * @param db - The Firestore database instance.
     * @param campaignCode - The unique code of the campaign to be fetched.
     * @returns The data of the campaign if found, otherwise returns null.
     */
    async getCampaignByCode(db: Firestore, campaignCode: string) {
      const campaignsRef = collection(db, 'campaigns')
      const q = query(
        campaignsRef,
        where('campaignCode', '==', campaignCode),
        where('isDeleted', '==', false)
      )
      const querySnapshot = await getDocs(q)
      if (querySnapshot.empty) {
        return null
      } else {
        const doc = querySnapshot.docs[0]
        const data = doc.data() as Campaign
        return {
          ...data,
          id: doc.id,
        }
      }
    },

    /**
     * Get data using cloud function getEndResultsByCampaignCodeForManagers
     *
     * @param campaignCode- The name of the campaign to query results for.
     * @returns {Result[]}
     *
     */
    async getResults(campaignCode: string) {
      const getEndedResultsByCampaignCodeForManagers = httpsCallable(
        functions,
        'getEndedResultsByCampaignCodeForManagers',
        {
          timeout: 600000,
        }
      )
      const response = await getEndedResultsByCampaignCodeForManagers({
        campaignCode,
      })

      return response.data as Result[]
    },
    /**
     * Updates the table headers for the campaign results table.
     */
    updateHeaders() {
      this.headers = this.getHeaders()
    },
    /**
     * Generates the table headers for the campaign results table.
     * Each header contains the text to be displayed, the value used for sorting, and whether it is sortable.
     * @returns {TableHeader[]} An array of table headers.
     */
    getHeaders(): TableHeader[] {
      return [
        {
          text: this.$t('campaignResult.tableHeader.couponCode') as string,
          value: 'couponCode',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.displayName') as string,
          value: 'displayName',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.dialogId') as string,
          value: 'dialogId',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.status') as string,
          value: 'status',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.startedAt') as string,
          value: 'startedAt',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.completedAt') as string,
          value: 'completedAt',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.duration') as string,
          value: 'duration',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.overall') as string,
          value: 'overall',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.range') as string,
          value: 'range',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.accuracy') as string,
          value: 'accuracy',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.phonology') as string,
          value: 'phonology',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.fluency') as string,
          value: 'fluency',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.coherence') as string,
          value: 'coherence',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('campaignResult.tableHeader.interaction') as string,
          value: 'interaction',
          align: 'center',
          sortable: true,
        },
      ]
    },
    /**
     * Determines the status of a campaign based on various conditions.
     * @param {boolean} isConfirmed - Indicates if the coupon is confirmed.
     * @param {boolean} isAssessed - Indicates if the dialog has been assessed.
     * @param {boolean} isEnded - Indicates if the dialog has ended.
     * @returns {string} - The status of the campaign.
     */
    determineStatus(
      isConfirmedOrStatus: boolean | string,
      isAssessed?: boolean,
      isEnded?: boolean
    ): string {
      if (typeof isConfirmedOrStatus === 'string') {
        // status string
        switch (isConfirmedOrStatus) {
          case '登録済み':
          case 'Registered':
            return this.$t('campaignResult.status.registered') as string
          case '分析完了':
          case 'Analyzed':
            return this.$t('campaignResult.status.analyzed') as string
          case '対話完了':
          case 'Completed':
            return this.$t('campaignResult.status.completed') as string
          case '未登録':
          case 'Unregistered':
            return this.$t('campaignResult.status.unregistered') as string
          default:
            return ''
        }
      } else {
        // boolean
        const isConfirmed = isConfirmedOrStatus
        switch (true) {
          case !isConfirmed:
            return this.$t('campaignResult.status.unregistered') as string

          case isConfirmed && isEnded && isAssessed:
            return this.$t('campaignResult.status.analyzed') as string

          case isConfirmed && isEnded:
            return this.$t('campaignResult.status.completed') as string

          case isConfirmed:
            return this.$t('campaignResult.status.registered') as string

          default:
            return ''
        }
      }
    },
    cefrStyleClass,
    /**
     * Updates the status text for each row in the table.
     */
    updateStatusTexts() {
      this.rows.forEach((row) => {
        row.status = this.determineStatus(row.status)
      })
    },
    /**
     * Adds a scroll event listener to handle fixed columns.
     */
    addScrollListener() {
      const dataTableRef = this.$refs.dataTable as Vue

      if (dataTableRef && dataTableRef.$el) {
        const tableWrapper = dataTableRef.$el.querySelector(
          '.v-data-table__wrapper'
        ) as HTMLElement

        if (tableWrapper) {
          tableWrapper.addEventListener('scroll', this.handleScroll)
        }
      }
    },
    /**
     * Handles the scroll event to keep fixed columns in place.
     */
    handleScroll(event: Event) {
      const tableWrapper = event.target as HTMLElement
      const left = tableWrapper.scrollLeft
      const fixedColumns = tableWrapper.querySelectorAll(
        'th:nth-child(-n+4), td:nth-child(-n+4)'
      )

      fixedColumns.forEach((column) => {
        ;(column as HTMLElement).style.transform = `translateX(${left}px)`
      })
    },
    roundToTwoDecimals,

    async getLearners(
      coupons: Coupon[]
    ): Promise<{ id: string; displayName: string }[]> {
      try {
        const learnerIds = coupons
          .filter((coupon) => coupon && coupon.learnerId)
          .map((coupon) => coupon.learnerId)
        const uniqueLearnerIds: string[] = []
        for (const learnerId of learnerIds) {
          if (!uniqueLearnerIds.includes(learnerId)) {
            uniqueLearnerIds.push(learnerId)
          }
        }
        const learners = await Promise.all(
          uniqueLearnerIds.map(async (learnerId) => {
            const learnerSnapshot = await getDoc(doc(db, 'learners', learnerId))
            const learnerData = learnerSnapshot.data()
            return {
              id: learnerId,
              displayName: learnerData?.displayName ?? '',
            }
          })
        )

        return learners
      } catch (error) {
        console.error(error)
        return []
      }
    },
  },
})
</script>
